<template>
	<div class="page-container">
		<nav-bar title="远程教育在线报名" :fixed="true" id="navbar"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#FF0000" background="#ecf9ff" left-icon="info-o">
				请您如实填写以下报名信息
			</van-notice-bar>
			<van-form @submit="onConfirm" ref="formData" action="">
				<div class="title" v-if="form.agentId > 0">
					<span style="color: #888;">代理编号：{{ form.agentId }}</span>
				</div>
				<van-field
					v-model="form.studentName"
					type="input"
					name="studentName"
					center
					clearable
					label="学员姓名"
					placeholder="请输入真实姓名"
					maxlength="20"
					:rules="[{ required: true, message: '请填写真实姓名' }]"
				>
				</van-field>
				<van-field
					v-model="form.certNum"
					type="input"
					name="certNum"
					center
					clearable
					label="身份证号"
					placeholder="请输入身份证号码"
					maxlength="18"
					:rules="[{ required: true, pattern: idCardPattern, message: '请输入正确的证件号' }]"
				/>
				<div>
					<!-- 性别 -->
					<van-field name="sex" label="性别" v-if="canBeSearch">
						<template #input>
							<van-radio-group v-model="form.sex" direction="horizontal">
								<van-radio name="男">男</van-radio>
								<van-radio name="女">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 手机号码 -->
					<van-field
						v-model="form.mobile"
						name="moblie"
						type="tel"
						label="手机号码"
						placeholder="手机号码"
						:rules="[{ required: true, pattern: mobilePattern, message: '请输入正确的手机号' }]"
						maxlength="11"
					/>
					<van-field
						v-model="fieldValue"
						name="fieldValue"
						is-link
						readonly
						label="可选课程"
						placeholder="请选择报名培训课程"
						@click="show = true"
					/>
					<van-popup v-model="show" round position="bottom">
						<van-cascader
							v-model="cascaderValue"
							title="请选择报名培训课程"
							:options="options"
							@close="show = false"
							@finish="onFinish"
						/>
					</van-popup>
					<van-cell is-link title="培训车型" @click="show1 = true" :value="form.trainType"
							  label="请选择报名培训车型"/>
					<van-action-sheet v-model="show1" :actions="actions" @select="onSelect" title="请选择报名培训车型"/>
					<van-cell
						title="课程名称"
						:value="planName"
						label="请您仔细确认课程名称无误"
					/>
					<!-- <div style="margin-top: 10px;">
						<div style="text-align: center">
							<van-uploader v-model="photoList" :after-read="afterRead" :max-count="1" />
						</div>
						<div style="text-align: center">学员照片上传，五官清晰可见</div>
					</div> -->
					<div style="margin: 10px 0; padding: 0 16px; height: 130px;" v-if="needIdCardUpload">
						<div style="text-align: center; height: 30px; line-height: 30px;">
							请您点击下方拍照区域上传身份证正反面照片
						</div>
						<div style="float: left">
							<div class="cropper">
								<img :src="idCardFront" class="img" alt="身份证正面照片"/>
								<h5-cropper :option="cropperOption"
											@getbase64="getBase64DataForIdCardFront"></h5-cropper>
							</div>
						</div>
						<div style="float: right">
							<div class="cropper">
								<img :src="idCardBack" class="img" alt="身份证背面照片"/>
								<h5-cropper :option="cropperOption"
											@getbase64="getBase64DataForIdCardBack"></h5-cropper>
							</div>
						</div>
					</div>
					<div style="margin: 16px">
						<van-button round block type="info" native-type="submit">下一步</van-button>
					</div>
					<p style="text-align: center"><span>客服：驾考2886947 资格证2089038 驾照报名 2021263</span></p>
				</div>
			</van-form>
		</div>
		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName"/>
					<van-cell title="身份证号" :value="form.certNum"/>
					<van-cell title="性别" :value="form.sex" v-if="form.sex"/>
					<van-cell title="手机号码" :value="form.mobile"/>
					<van-cell title="报名课程" :value="fieldValue"/>
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改</van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功！</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName"/>
					<van-cell title="身份证号" :value="form.certNum"/>
					<van-cell title="性别" :value="form.sex" v-if="form.sex"/>
					<van-cell title="手机号码" :value="form.mobile"/>
					<van-cell title="培训车型" :value="form.trainType"/>
					<van-cell title="报名课程" :value="fieldValue"/>
					<div style="margin: 16px">
						<van-button
							round
							block
							type="info"
							native-type="button"
							@click="gotoStudy"
						>点击进入在线教育平台，开始学习吧！
						</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import {NavBar, Toast, Dialog} from 'vant';
import {getStudyPlanInfoById, whCertificateSignUp, uploadIdCardFrontAndBack} from '../../api/user';
import {wipeBase64Rrefix} from '../../utils';
import H5Cropper from 'vue-cropper-h5'

export default {
	name: "wuhai-certificate",
	components: {NavBar, H5Cropper},
	data() {
		return {
			idCardFront: require('@/assets/idcard/idcard_f.png'),
			idCardBack: require('@/assets/idcard/idcard_b.png'),
			idCardFrontUploaded: false,
			idCardBackUploaded: false,
			needIdCardUpload: false,
			title: '',
			canBeSearch: true,
			showLoading: false,
			isSignupSuccess: false,
			isSignupConfirm: false,
			searchResult: false,
			showPicker: false,
			showPickerNd: false,
			showPickerDq: false,
			showPickerSy: false,
			form: {
				certNum: '',
				studentName: '',
				agentId: 0,
				studentPic: '',
				trainType: '',
			},
			bookCount: 0,
			bookList: [],
			show: false,
			show1: false,
			fieldValue: "",
			mobilePattern: /^1[0-9]{10}$/,
			idCardPattern: /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))|^([A-Z]\d{8}(\(\w{1}\))?)$/,
			cascaderValue: "",
			categroy: "",
			pCategory: "",
			planName: "",
			actions: [
				{name: 'A1'},
				{name: 'A2'},
				{name: 'A3'},
				{name: 'A3'},
				{name: 'B1'},
				{name: 'B2'},
				{name: 'C1/C2'},
				{name: 'C5'},
				{name: 'A6'},
				{name: 'D/E'},
			],
			options: [{
				text: "初领/增驾",
				value: "279",
				children: [
					{text: "小车(C1/C2)", value: "279"},
					{text: "摩托车(D/E)", value: "280"},
					{text: "客车（A1）", value: "283"},
					{text: "货车（A2）", value: "281"},
					{text: "客车（A3）", value: "284"},
					{text: "客车（B1）", value: "289"},
					{text: "货车（B2）", value: "282"},
					{text: "小车(C5)", value: "290"},
					{text: "小车(C6)", value: "291"},
				],
			}, {
				text: "资格证培训",
				value: "208",
				children: [
					{text: "出租汽车驾驶员培训", value: "208"},
					{text: "道路旅客运输驾驶员培训", value: "207"},
					// { text: "道路货物运输驾驶员培训", value: "205" },
					{text: "道路危险货物运输驾驶员培训", value: "204"},
					{text: "爆炸品道路运输驾驶员培训", value: "275"},
					// { text: "剧毒品路运输驾驶员培训", value: "276" },
					{text: "道路危险货物运输押运员培训", value: "206"},
					{text: "爆炸品道路运输押运员培训", value: "277"},
					// { text: "剧毒品路运输押运员培训", value: "278" },
				],
			},
			],
			photoList: [],
			cropperOption: {
				fixedNumber: [1.6, 1],
				fixedBox: false,
				canMoveBox: true,
				outputType: 'jpeg',
				outputSize: 0.8
			}
		}
	},
	mounted() {
		const {agent} = this.$route.query;
		if (typeof (agent) !== 'undefined') {
			this.form.agentId = agent;
		}
		// 乌海安泰需要身份证上传
		if (location.host.startsWith('antai.')) {
			this.needIdCardUpload = true;
		}
	},
	methods: {
		onSelect(item) {
			// 默认情况下点击选项时不会自动收起
			// 可以通过 close-on-click-action 属性开启自动收起
			this.show1 = false;
			this.form.trainType = item.name;
		},
		afterRead(file) {
			//this.photoList.push({'url': file.content, isImage: true});
			this.form.studentPic = wipeBase64Rrefix(file.content);
		},
		reset() {
			this.columns = [];
			this.bookCount = 0;
			this.bookList = [];
		},
		getPlanInfo(planId) {
			getStudyPlanInfoById(planId)
				.then((res) => {
					this.planName = res.planName;
				})
				.catch(() => {
				});
		},
		onConfirm() {
			if (typeof (this.form.studentName) === 'undefined' || this.form.studentName === '' || this.form.studentName == null) {
				return;
			}
			if (typeof (this.form.certNum) === 'undefined' || this.form.certNum === '' || this.form.certNum == null) {
				return;
			}
			if (!this.form.mobile) {
				Toast.fail("请您填写报名手机号码");
				return;
			}
			if (this.fieldValue === "") {
				Toast.fail("请您选择报名课程");
				return;
			}
			if (this.form.trainType === "") {
				Toast.fail("请您选择培训车型");
				return;
			}
			if (this.needIdCardUpload) {
				if (!this.idCardFrontUploaded) {
					Toast.fail("请您上传身份证正面照片");
					return;
				}
				if (!this.idCardBackUploaded) {
					Toast.fail("请您上传身份证背面照片");
					return;
				}
			}
			this.isSignupConfirm = true;
		},
		onModify() {
			this.isSignupConfirm = false;
		},
		onSubmit() {
			this.showLoading = true;
			this.form.planId = this.categroy;
			whCertificateSignUp(this.form)
				.then(() => {
					if (this.idCardBackUploaded && this.idCardFrontUploaded) {
						uploadIdCardFrontAndBack({
							certNum: this.form.certNum,
							idCardFront: this.idCardFront,
							idCardBack: this.idCardBack
						}).then(() => {
							this.isSignupSuccess = true;
							this.isSignupConfirm = false;
							this.showLoading = false;
						}).catch(() => {
							this.showLoading = false;
						});
					} else {
						this.isSignupSuccess = true;
						this.isSignupConfirm = false;
						this.showLoading = false;
					}
				})
				.catch(() => {
					this.showLoading = false;
				});
		},
		onConfirmPicker(value) {
			for (let i = 0; i < this.bookList.length; i++) {
				if (this.bookList[i].id_ECERT === value.value) {
					this.form.cyzglb = value.text;
					this.form.cyzglb_value = value.value;
					break;
				}
			}
			this.showPicker = false;
		},
		onConfirmPickerNd(value) {
			this.form.pxnd = value.text;
			this.form.pxnd_value = value.value;
			this.showPickerNd = false;
		},
		onConfirmPickerDq(value) {
			this.form.szdq = value.text;
			this.form.szdq_value = value.value;
			this.form.sydq = value.text;
			this.form.sydq_value = value.value;
			if (value.value === '150300') {
				this.canBeSearch = true;
				this.searchResult = false;
				this.loadStudyPlan(value.value);
			} else if (value.value === '152900') {
				this.canBeSearch = true;
				this.loadStudyPlan(value.value);
				this.searchResult = false;
			}
			this.showPickerDq = false;
		},
		onConfirmPickerSy(value) {
			this.form.sydq = value.text;
			this.form.sydq_value = value.value;
			this.showPickerSy = false;
		},
		gotoStudy() {
			if (this.form.planId >= 279) {
				//继续教育
				window.location.href = "/#/login?appNo=APP01";
			} else {
				// 默认
				window.location.href = "/#/login?appNo=APP02";
			}
		},
		loadStudyPlan(cityCode) {
			if (cityCode === '150300') {
				this.$set(this, 'options', this.options_wh);
			} else if (cityCode === '152900') {
				this.$set(this, 'options', this.options_als);
			}
		},
		onFinish({selectedOptions}) {
			this.pCategory = selectedOptions[0].value;
			this.categroy = selectedOptions[selectedOptions.length - 1].value;
			this.show = false;
			this.fieldValue = selectedOptions.map((option) => option.text).join(" / ");
			this.getPlanInfo(this.categroy);

			switch (this.categroy) {
				case'279':
					this.actions = [{name: 'C1'}, {name: 'C2'}]
					break;
				case'280':
					this.actions = [{name: 'D'}, {name: 'E'}]
					break;
				case'281':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证才可申领当地资格证！',
					}).then(() => {
						// on close
					});
					this.actions = [{name: 'A2'}]
					break;
				case'282':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证才可申领当地资格证！',
					}).then(() => {
						// on close
					});
					this.actions = [{name: 'B2'}]
					break;
				case'283':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证才可申领当地资格证！',
					}).then(() => {
						// on close
					});
					this.actions = [{name: 'A1'}]
					break;
				case'284':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证才可申领当地资格证！',
					}).then(() => {
						// on close
					});
					this.actions = [{name: 'A3'}]
					break;
				case'289':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证才可申领当地资格证！',
					}).then(() => {
						// on close
					});
					this.actions = [{name: 'B1'}]
					break;
				case'290':
					this.actions = [{name: 'C5'}]
					break;
				case'291':
					this.actions = [{name: 'C6'}]
					break;
				case'208':
				case'207':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：外地学员需持有乌海本地居住证！',
					}).then(() => {
						// on close
					});
					break;
				case'204':
				case'275':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：据乌海当地需求，学员报名危货课程须持有乌海当地两年以上普货资格证才可学习。（外地学员需持有乌海本地居住证）',
					}).then(() => {
						// on close
					});
					break;
				case'206':
				case'277':
					Dialog.alert({
						title: '温馨提示',
						message: '请悉知：据乌海当地需求,学员报名押运员，需持有初中以上毕业证书。（外地学员需持有乌海本地居住证）',
					}).then(() => {
						// on close
					});
					break;
			}
		},
		getBase64DataForIdCardFront(data) {
			this.idCardFront = data;
			this.idCardFrontUploaded = true;
		},
		getBase64DataForIdCardBack(data) {
			this.idCardBack = data;
			this.idCardBackUploaded = true;
		}
	}
}
</script>

<style scoped>
#navbar {
	background-color: #409eff;
}

#navbar /deep/ .van-ellipsis {
	color: #fff !important;
}

.form-area {
	margin-top: 46px;
}

.title {
	margin: 0;
	padding: 16px 16px 16px;
	color: rgba(69, 90, 100, 0.6);
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}

.checked-area {
	margin-top: 90px;
	text-align: center;
}

.confirm-area {
	text-align: center;
}

.checked-msg {
	font-size: 14px;
	color: #67c23a;
}

.checked-plan {
	text-align: left;
	margin-top: 40px;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	width: 120px;
	height: 120px;
	border-radius: 15px;
	background-color: #fff;
	padding-top: 32px;
}

.cropper {
	width: 160px;
	height: 100px;
	line-height: 100px;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	text-align: center;
}

.img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
</style>
