import { render, staticRenderFns } from "./wuhai_certificate.vue?vue&type=template&id=d190ce6e&scoped=true&"
import script from "./wuhai_certificate.vue?vue&type=script&lang=js&"
export * from "./wuhai_certificate.vue?vue&type=script&lang=js&"
import style0 from "./wuhai_certificate.vue?vue&type=style&index=0&id=d190ce6e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d190ce6e",
  null
  
)

export default component.exports